import React, { useState, useRef, useEffect } from 'react';
import './Initiation.css'; // This is the same CSS file we used before, now adapted for your requirements
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import TopBar from './TopBar';

const Initiation = ({ onUploadComplete,
    navPaneExtended, 
    toggleNavPane, 
    checkAuth, 
    triggerRefetch,
 }) => {
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);
    const [innerWorkflowId, setWorkflowId] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();

    const handleFileChange = (event) => {
        const allowedExtensions = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];
    
        const newFiles = Array.from(event.target.files).filter(file => {
            // Check if the file type is allowed
            if (!allowedExtensions.includes(file.type)) {
                setErrorMessage('File extension not allowed')
                setTimeout(() => {
                    setErrorMessage(undefined);
                  }, 5000);
                return false;
            }
            return true;
        }).map(file => ({
            file,
            name: file.name,
            size: (file.size / (1024 * 1024)).toFixed(2), // size in MB
            progress: 0
        }));
    
        // If valid files exist, update the state
        if (newFiles.length > 0) {
            setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        }
    
        // Reset the input value to allow re-uploading the same file
        event.target.value = null;
    };

    useEffect(() => {
        const fetchUser = async () => {
            try {
               
                const response = await api.get('/get-user'); // Adjust this endpoint as needed
                setName(response.data.name);
                console.log(response.data)
             
            } catch (error) {
                console.error('Failed to fetch user data:', error);
              
            } finally {
            
            }
        };

        fetchUser();
    }, []);

    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files).map(file => ({
            file,
            name: file.name,
            size: (file.size / (1024 * 1024)).toFixed(2), // size in MB
            progress: 0
        }));
        setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const uploadFiles = async () => {
        let params = new URLSearchParams();
        params.append('workflow_title', 'First Workflow');
        const response = await api.post('/create-workflow', {}, {
            params: params,
        });
        console.log('response', response.data);
        let workflowId = response.data.workflow_id;
        console.log(workflowId)
        // setWorkflowId(response.data.workflow_id);
        setLoading(true);
        for (let i = 0; i < files.length; i++) {
            
            const formData = new FormData();
            formData.append('file', files[i].file);
           
            
            let params2 = new URLSearchParams();
            params2.append('workflow_id', workflowId);

          

            try {
                const response = await api.post('/upload', formData, {
                    params: params2,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    withCredentials: true,
                    onUploadProgress: (progressEvent) => {
                        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress((prevProgress) => ({
                            ...prevProgress,
                            [i]: progress
                        }));
                    }
                });
                console.log('File uploaded:', response);
            } catch (error) {
                console.error('Error uploading file', error);
            }
        }
        setLoading(false);
        setFiles([]); // Clear the files after upload
        onUploadComplete();
        navigate('/w/workflow/' + workflowId);
    };

    const handleRemoveFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        setUploadProgress((prevProgress) => {
            const newProgress = { ...prevProgress };
            delete newProgress[index];
            return newProgress;
        });
    };

    return (
        <div className="welcome-page">
                  <TopBar 
        navPaneExtended={navPaneExtended} 
        toggleNavPane={toggleNavPane} 
        checkAuth={checkAuth} 
        title="New Workflow"
        triggerRefetch={triggerRefetch}
      />

{errorMessage && (
        <div className="error-box">
          {errorMessage}
        </div>
      )}
         
           <h1>Welcome to <strong>deductiv</strong>, {name}</h1>
            <p>Import your documents and let deductiv do its magic.</p>

            <div className="init-file">
                <div className="action-container">
                    <div className="action-box import-box"
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onClick={() => { fileInputRef.current.click() }}>
                        <FontAwesomeIcon icon='fa-solid fa-upload' />
                        <h2>Import</h2>
                        <p>Add files into your library</p>
                        <p>PDF, DOC, PPT</p>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            className="file-input"
                            ref={fileInputRef}
                            accept=".pdf, .doc, .docx, .ppt, .pptx"
                            multiple
                            hidden
                        />
                    </div>
                </div>

                <div className="init-staging-area">
                    {files.map((file, index) => (
                        <div key={index} className="staged-file">
                            <div className="file-icon">
                                <FontAwesomeIcon icon='fa-solid fa-file' />
                            </div>
                            <div className="file-info">
                                <span className='filename'>{file.name}</span>
                                <span>{file.size} MB</span>
                                <progress value={uploadProgress[index] || 0} max="100" />
                                <div

                                    className="remove-btn"
                                    onClick={() => handleRemoveFile(index)}
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-times" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* Upload button */}

                    <div className={`init-upload-btn ${files.length === 0 || loading ? 'disabled': ''}`}  onClick={uploadFiles}>{ loading && <div className='loader'></div>} Upload</div>

            </div>
        </div>
    );
};

export default Initiation;
