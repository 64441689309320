import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { highlightPlugin } from '@react-pdf-viewer/highlight';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { pageNavigationPlugin, RenderGoToPageProps } from '@react-pdf-viewer/page-navigation';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';


import './DocumentViewer.css';
import api from '../../services/api';

const DocumentViewer = forwardRef(({ className, objectId, objectType, currentWorkflow, setTitle }, ref) => {
  const [fileUrls, setFileUrls] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const viewerRef = useRef(null);

  

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const highlightPluginInstance = highlightPlugin();
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();

  const [underlineStyle, setUnderlineStyle] = useState({});

  const { jumpToPage } = pageNavigationPluginInstance;

  useImperativeHandle(ref, () => ({
    scrollToPage: (pageNumber) => {
      console.log(jumpToPage)
    
      if (jumpToPage) {

        jumpToPage(pageNumber - 1);  // PDF page numbers are 0-based
      }
    }
  }));

  

  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <div className="custom-toolbar">
            <div className="left-section">
              <GoToPreviousPage />
              <CurrentPageInput /> of <NumberOfPages />
              <GoToNextPage />
            </div>
            <div className="right-section">
              <ZoomOut />
              <Zoom />
              <ZoomIn />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  // const customLayoutPlugin = defaultLayoutPlugin({
  //   renderToolbar,
  //   // renderGoToPreviousPage,
  //   // renderGoToNextPage,
  // });

  const customLayoutPlugin = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => defaultTabs.filter((tab) => tab.title !== 'Attachment'),
  });



  const scrollLeft = () => {
    const container = document.querySelector('.tabs-container');
    if (container) {
      container.scrollBy({ left: -container.offsetWidth, behavior: 'smooth' });
    }
  };
  
  const scrollRight = () => {
    const container = document.querySelector('.tabs-container');
    if (container) {
      container.scrollBy({ left: container.offsetWidth, behavior: 'smooth' });
    }
  };


  useEffect(() => {
    const activeTab = document.querySelector('.tab.active');
    const tabsContainer = document.querySelector('.tabs-container');
    if (activeTab && tabsContainer) {
      const containerRect = tabsContainer.getBoundingClientRect();
      const tabRect = activeTab.getBoundingClientRect();
      
      setUnderlineStyle({
        left: (tabRect.left - containerRect.left + tabsContainer.scrollLeft) + 'px',
        width: tabRect.width + 'px',
      });
  
      // Scroll to make the active tab visible
      activeTab.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, [activeTabIndex]);



  const fetchDocument = async (documentId) => {
    try {
      const response = await api.get('/document', {
        params: { document_id: documentId },
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      return URL.createObjectURL(response.data);
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  useEffect(() => {
    const handleFetch = async () => {
      console.log('fetching documents');
      console.log(objectType);
      if (objectType === 'document' && objectId) {
        
        const url = await fetchDocument(objectId);
        console.log(url);
        if (url) {
          setFileUrls([url]);
        }
      } else if (objectType === 'workflow' && currentWorkflow) {
        setTitle(currentWorkflow.name);
        const urls = await Promise.all(
          currentWorkflow.documents.map((doc) => fetchDocument(doc.document_id))
        );
        setFileUrls(urls.filter(Boolean));
      }
    };
    try{
      handleFetch();
    }catch(e){
      console.log(e)
    }
  }, [objectId, objectType, currentWorkflow]);

  return (
    <div className={` document-viewer ${className}`}>
      {fileUrls.length > 1 && (
        <>
          {objectType === 'workflow' && (
            <div className="tabs-container">
              
            <div className="tabs">
              {currentWorkflow.documents.map((doc, index) => (
                <button
                  key={doc.document_id}
                  className={`tab ${index === activeTabIndex ? 'active' : ''}`}
                  onClick={() => setActiveTabIndex(index)}
                >
                  {doc.title}
                </button>
              ))}
              <div className="tab-underline" style={underlineStyle}></div>
            </div>
            
          </div>
          
          )}
          <div className="pdf-container">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
              <Viewer
                fileUrl={fileUrls[activeTabIndex]}
                plugins={[
                  // defaultLayoutPluginInstance,
                  // highlightPluginInstance,
                  // zoomPluginInstance,
                  customLayoutPlugin,
                  pageNavigationPluginInstance
                ]}
                ref={viewerRef}
                defaultScale={SpecialZoomLevel.PageFit}
              />
            </Worker>
          </div>
        </>
      )}
       {fileUrls.length === 1 && (
        
          <div className="pdf-container">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
              <Viewer
                fileUrl={fileUrls[0]}
                plugins={[
                  // defaultLayoutPluginInstance,
                  // highlightPluginInstance,
                  // zoomPluginInstance,
                  customLayoutPlugin,
                  pageNavigationPluginInstance
                ]}
                ref={viewerRef}

                defaultScale={SpecialZoomLevel.PageFit}
              />
            </Worker>
          </div>
       
      )}
    </div>
  );
});

export default DocumentViewer;
