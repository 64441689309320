// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useParams, useLocation } from 'react-router-dom';
import MainLayout from './components/layout/MainLayout';
import AuthLayout from './components/layout/AuthLayout';
import MainView from './components/layout/MainView';
import DocumentUpload from './components/layout/DocumentUpload';
import Register from './components/layout/Register';
import Modal from 'react-modal';
import Login from './components/layout/Login'; // Ensure consistent capitalization
import './App.css';
import NewWorkflow from './components/layout/NewWorkflow';
import NotAuthorized from './components/errors/NotAuthorized';
import useWorkflows from './hooks/useWorkflows';
import useAuth from './hooks/useAuth';
import Flashcards from './components/layout/Flashcards';
import { createSlice } from '@reduxjs/toolkit';
import DynamicModal from './components/common/Modal';
import { useDispatch } from 'react-redux';
import { openModal } from './store/modalSlice';
import Quizzes from './components/layout/Quizzes';
import NotFound from './components/errors/NotFound';
import Initiation from './components/layout/Initiation';
import VerifyEmail from './components/common/VerifyEmail';
import { AppProvider } from './contexts/AppContext';
import Mindmap from './components/layout/Mindmap';

const ExampleModalContent = ({ message, onClose }) => (
  <div>
    <h2>Example Modal</h2>
    <p>{message}</p>
    <button onClick={onClose}>Close</button>
  </div>
);

const AnotherModalContent = ({ title, items, onClose }) => (
  <div>
    <h2>{title}</h2>
    <ul>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
    <button onClick={onClose}>Close</button>
  </div>
);


function App() {



  const [navPaneExtended, toggleNavPane] = useState(true);
  const [documentModalIsOpen, newDocumentModal] = useState(false);
  const [workflowModalIsOpen, newWorkflowModal] = useState(false);
  const [currentWorkflowId, setCurrentWorkflowId] = useState(null);
  const [currentWorkflow, setCurrentWorkflow] = useState(null);
  const [newDocumentWorkflowId, setNewDocumentWorkflowId] = useState(null);
  const [currentDocumentId, setCurrentDocumentId] = useState(null);
  const [refetchTrigger, setRefetchTrigger] = useState(0);
  const [objectType, setObjectType] = useState(null);
  const [objectId, setObjectId] = useState(null);
  const [errorMsg, setErrorMsg] = useState("Dest ");
  const { workflows, setWorkflows, expandedWorkflows, toggleWorkflow } = useWorkflows(refetchTrigger);
  const dispatch = useDispatch();


  const { isAuthenticated, loading, checkAuth } = useAuth();

  const triggerRefetch = () => {
    setRefetchTrigger(prev => prev + 1);
  };



  const openNewModal = () => {
    dispatch(openModal({ modalType: 'example', modalProps: { message: 'Hello from Redux!' } }));
  };


  useEffect(() => {
    if (newDocumentWorkflowId)
      newDocumentModal(true);
  }, [newDocumentWorkflowId]);


  const closeDocumentModal = () => {
    newDocumentModal(false);
    setNewDocumentWorkflowId(null);
  };

  const openWorkflowModal = () => {
    console.log('open workflow modal');
    newWorkflowModal(true);
  };

  const closeWorkflowModal = () => {
    newWorkflowModal(false);

  };


  if (loading) {
    return <div>Loading...</div>;
  }





  return (
    <AppProvider className="main-app">
      <Router>

        <div className="app-container">
          <Routes>
            <Route path="/" element={<Navigate to={isAuthenticated ? "/w" : "/auth/login"} />} />
            <Route path="/w" element={<MainLayout
              navPaneExtended={navPaneExtended}
              toggleNavPane={toggleNavPane}
              setCurrentWorkflowId={setCurrentWorkflowId}
              currentWorkflowId={currentWorkflowId}
              setNewDocumentWorkflowId={setNewDocumentWorkflowId}
              setCurrentDocumentId={setCurrentDocumentId}
              openWorkflowModal={openWorkflowModal}
              refetchTrigger={refetchTrigger}
              triggerRefetch={triggerRefetch}
              setCurrentWorkflow={setCurrentWorkflow}
              setObjectType={setObjectType}
              setObjectId={setObjectId}
              workflows={workflows}
              setWorkflows={setWorkflows}
              expandedWorkflows={expandedWorkflows}
              toggleWorkflow={toggleWorkflow}
            />}>
              <Route path="/w/workflow/:workflowId"
                element={<WorkflowViewWrapper
                  currentWorkflowId={currentWorkflowId}
                  setCurrentWorkflowId={setCurrentWorkflowId}
                  currentDocumentId={currentDocumentId}
                  navPaneExtended={navPaneExtended}
                  toggleNavPane={toggleNavPane}
                  checkAuth={checkAuth}
                  currentWorkflow={currentWorkflow}
                  objectId={objectId}
                  objectType={objectType}
                  workflows={workflows}
                  setCurrentWorkflow={setCurrentWorkflow}
                  triggerRefetch={triggerRefetch}
                />} />
              <Route
                path="/w/new"
                element={<Initiation
                  onUploadComplete={triggerRefetch}
                  toggleNavPane={toggleNavPane}
                  navPaneExtended={navPaneExtended}
                  checkAuth={checkAuth}
                  triggerRefetch={triggerRefetch}
                />} />
              <Route path="/w/flashcards" element={<Flashcards
                navPaneExtended={navPaneExtended}
                toggleNavPane={toggleNavPane}
                checkAuth={checkAuth}
                triggerRefetch={triggerRefetch} />} />
              <Route path="/w/quizzes"
                element={<Quizzes
                  navPaneExtended={navPaneExtended}
                  toggleNavPane={toggleNavPane}
                  checkAuth={checkAuth}
                  triggerRefetch={triggerRefetch} />} />
              <Route path="/w/mindmaps"
                element={<Mindmap
                  navPaneExtended={navPaneExtended}
                  toggleNavPane={toggleNavPane}
                  checkAuth={checkAuth}
                  triggerRefetch={triggerRefetch} />} />
            </Route>


            <Route
              path="/auth"
              element={<AuthLayout
                setWorkflows={setWorkflows}
                checkAuth={checkAuth}
                triggerRefetch={triggerRefetch}
                error={errorMsg}
              />}>
              <Route path="/auth/register" element={<Register setErrorMsg={setErrorMsg} />} />
              <Route
                path="/auth/login"
                element={<Login
                  setWorkflows={setWorkflows}
                  triggerRefetch={triggerRefetch}
                  checkAuth={checkAuth} />
                } />
            </Route>
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/not-authorized" element={<NotAuthorized />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        {/* <Modal
          isOpen={documentModalIsOpen}
          onRequestClose={closeDocumentModal}
          contentLabel="Upload Document"
          overlayClassName="overlay"
          closeTimeoutMS={300}
          ariaHideApp={false}
          className={{
            base: 'modal',
            afterOpen: 'modal--after-open',
            beforeClose: 'modal--before-close'
          }}
        >
          <DocumentUpload currentWorkflowId={newDocumentWorkflowId} closeModal={closeDocumentModal} onUploadComplete={triggerRefetch} />
        </Modal>
        <Modal
          isOpen={workflowModalIsOpen}
          onRequestClose={closeWorkflowModal}
          contentLabel="Upload Document"
          overlayClassName="overlay"
          closeTimeoutMS={300}
          ariaHideApp={false}
          className={{
            base: 'modal',
            afterOpen: 'modal--after-open',
            beforeClose: 'modal--before-close'
          }}>
          <NewWorkflow closeModal={closeWorkflowModal} onUploadComplete={triggerRefetch} />
        </Modal> */}
        <DynamicModal />
      </Router>
    </AppProvider >
  );
}

function WorkflowViewWrapper({
  setCurrentWorkflowId,
  currentWorkflowId,
  currentDocumentId,
  navPaneExtended,
  toggleNavPane,
  checkAuth,
  currentWorkflow,
  objectId,
  objectType,
  workflows,
  triggerRefetch,
  setCurrentWorkflow }) {
  const { workflowId } = useParams();

  useEffect(() => {
    console.log('workflow id', typeof (workflowId));


    if (workflowId && workflows) {

      setCurrentWorkflowId(workflowId);
      setCurrentWorkflow(workflows.filter(w => w.id === parseInt(workflowId))[0]);

    }
  }, [workflowId, setCurrentWorkflowId, workflows, setCurrentWorkflow]);

  return (
    <MainView
      currentWorkflowId={currentWorkflowId}
      currentDocumentId={currentDocumentId}
      navPaneExtended={navPaneExtended}
      toggleNavPane={toggleNavPane}
      checkAuth={checkAuth}
      currentWorkflow={currentWorkflow}
      objectId={objectId}
      objectType={objectType}
      triggerRefetch={triggerRefetch}

    />
  );
}

export default App;
