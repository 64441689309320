import React, { useState, useEffect } from "react";
import "./Mindmap.css";
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/modalSlice';
import NewSet from "./NewSet";
import api from "../../services/api";
import TopBar from './TopBar';
import MarkmapComponent from "./Markmap";




const Mindmaps = ({ navPaneExtended, toggleNavPane, checkAuth, triggerRefetch }) => {
    const [documents, setDocuments] = useState([]);

    const [mindmaps, setMindmaps] = useState([]);


    const [selectedSetTitle, setSetTitle] = useState("");
    const [selectedSet, setSelectedSet] = useState(false);
    const [selectedMindmap, setSelectedMindmap] = useState(undefined)
    const [animate, setAnimate] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [markdownContent, setMarkdownContent] = useState('');



    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState([]);
    const [result, setResult] = useState(null);

    const getMindmaps = async () => {
        let response = await api.get("/mindmap-sets");
        console.log(response.data);
        setMindmaps(response.data);
    }

    useEffect(() => {
        api.get("/user-documents").then((response) => {
            console.log('documents')
            console.log(response);
            setDocuments(response.data);
        });
        getMindmaps();


    }, []);

    const openNewWorkflowModal = () => {
        dispatch(openModal({
            component: NewSet,
            props: { existingDocuments: documents, createSetapi: '/create-mindmaps', title: 'Mindmap', callBack: getMindmaps }
        }));
    };


    const handleContentChange = (updatedContent) => {
        setMarkdownContent(updatedContent);
    };


    const handleSetClick = async (setId) => {
        // Dummy data for flashcards in the selected set
        let response = await api.get(`/mindmap-set/${setId}`)
        console.log('mindmap response')
        console.log(response.data)



        setSelectedSet(setId);
        setSelectedMindmap(response.data[0])
   

      
        setAnimate(true); // Trigger the animation


    };


    const handleBackToSets = () => {
        setAnimate(false); // Reverse the animation
        setSelectedSet(null);
        setSelectedMindmap(undefined);
    };





    return (
        <div className="mp-container">
            <TopBar
                navPaneExtended={navPaneExtended}
                toggleNavPane={toggleNavPane}
                checkAuth={checkAuth}
                title="Mindmaps"
                newBtnClick={mindmaps.length > 0 ? openNewWorkflowModal : undefined}
                triggerRefetch={triggerRefetch} />

            {mindmaps.length === 0 && <div className="flc-empty">
                
                <div className="btn feedback" onClick={openNewWorkflowModal}>Create a set</div>
            
        </div>}

            {<div className="mp-internal">



                <div className={`mp-content ${animate ? "animate" : ""} ${selectedSet ? "set-selected" : ""}`}>
                    <div className="mp-sets">
                        {selectedSet && (
                            <div onClick={handleBackToSets} className="simple-btn">
                                &larr; Back to Sets
                            </div>
                        )}
                        <div className={`mp-sets-grid ${selectedSet ? "single" : ""}`}>
                            {mindmaps.map((mm) => (
                                <div
                                    key={mm.mindmap_set_id}
                                    className={`mp-card-item ${mm.mindmap_set_id === selectedSet ? "active" : ""
                                        }`}
                                    onClick={() => handleSetClick(mm.mindmap_set_id)}
                                >
                                    <h3>{mm.title}</h3>
                                    <img src="/bookset.jpg" alt="Set" />
                                </div>
                            ))}
                        </div>
                    </div>
                   {selectedSet && <div className="mp-set mindmap">
                        {selectedMindmap.content ? (
                            <MarkmapComponent markdownContent={selectedMindmap.content} onContentChange={handleContentChange} />
                        ) : (
                            <p>Loading mind map data...</p>

                        )}
                    </div>}

                </div>
            </div>}




        
        </div>
    );
};

export default Mindmaps;
