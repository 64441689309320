// src/Login.js

import React, { useState, useEffect } from 'react';

import './Login.css';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';


const Login = ({checkAuth, triggerRefetch, setWorkflows}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [loading, setLoading] = useState(false);
 


  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const params = new URLSearchParams();
    params.append('username', email);
    params.append('password', password);
    setWorkflows([]);
  
    try {
      const response = await api.post(
        '/login',
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          withCredentials: true,
        }
      );
      response.headers['Cache-Control'] = 'no-store, no-cache, must-revalidate, max-age=0'
      setLoading(false);
  
      if (response.status === 200) {
        await checkAuth();
        triggerRefetch();
        navigate('/');
      }
  
    } catch (error) {
      console.error(error);
      setLoading(false);
  
      // Handling specific errors based on status code
      if (error.response && error.response.status === 422) {
        setErrorMessage('Please validate your email address');
      } else if (error.response && error.response.status === 401) {
        setErrorMessage('Invalid username or password');
      } else {
        setErrorMessage('Something went wrong. Please try again.');
      }
  
      setTimeout(() => {
        setErrorMessage(undefined);
      }, 5000);
    }
  };
 

  const handleGoogleLoginFailure = (error) => {
    console.error("Google login failed:", error);
  };


  const handleFacebookLogin = async (response) => {
    try {
      const res = await api.post('/facebook-login', {
        accessToken: response.accessToken,
        userID: response.userID,
      });
      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (

    <div className="central-container">
       {errorMessage && (
        <div className="error-box">
          {errorMessage}
        </div>
      )}
      <form onSubmit={handleLogin}>
        <div className='input-container'>
          <input
            className='input-field'
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder=" "
            required
          />
          <label className='input-label'>Email</label>
        </div>
        <div className='input-container'>

          <input
            className='input-field'
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder=" "
            required
          />
          <label className='input-label'>Password</label>
        </div>
        <div class="options-row">

          <div class="remember-me">
            <div className='redirect-btn' onClick={()=>{navigate('/auth/register')}}> Register </div>
          </div>

          <div class="reset-password">
            <div className='redirect-btn'>Reset Password</div>
          </div>

        </div>
        <button type="submit" class='auth-submit'> {loading && <div class='loader'></div>}Login</button>
      </form>

    </div>






  );
};

export default Login;
